import React from 'react';
import { Box, Typography, Card, CardContent, Table, TableBody, TableCell, TableRow, Stack } from "@mui/material";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { ITopPurchaser } from '../../store/interfaces/ITopPurchaser';
import { useIsMobileView } from '../../utils/responsive';

interface TopPurchasersProps {
  topPurchasers: ITopPurchaser[];
}

const TopPurchasers: React.FC<TopPurchasersProps> = ({ topPurchasers }) => {
  const renderTrophyIcon = (index: number) => {
    if (index === 0) {
      return <EmojiEventsIcon sx={{ fontSize: 30, color: 'gold' }} />;
    } else if (index === 1) {
      return <EmojiEventsIcon sx={{ fontSize: 30, color: 'silver' }} />;
    } else if (index === 2) {
      return <EmojiEventsIcon sx={{ fontSize: 30, color: '#cd7f32' }} />;
    }
    return null;
  };

  const orderedTopPurchasers = [...topPurchasers].sort((a, b) => b.ticketCount - a.ticketCount);
  const isMobile = useIsMobileView();

  return (
    <Box mt={4} mx={isMobile ? 1 : 4}>

      {orderedTopPurchasers.length === 0 ? (
        <Box width={"100%"} textAlign={"center"}>
          <Typography color="textSecondary" variant="h6">No hay compradores.</Typography>
        </Box>
      ) : isMobile ? (
        <Box>
          {orderedTopPurchasers.map((purchaser, index) => (
            <Card key={purchaser.email} sx={{ marginBottom: 2, borderRadius: 2, boxShadow: 3, padding: 2, marginX: 10 }}>
              <CardContent sx={{ textAlign: 'center' }}>
                <Stack direction={"column"} spacing={1} alignItems="center">
                  <Box>
                    {renderTrophyIcon(index)}
                  </Box>
                  <Typography variant="h5" fontWeight="bold">{purchaser.ticketCount}</Typography>
                  <Typography variant="body1" color="textPrimary">{purchaser.name}</Typography>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        <Table>
          <TableBody>
            {orderedTopPurchasers.map((purchaser, index) => (
              <TableRow key={purchaser.email} sx={{ '&:nth-of-type(odd)': { backgroundColor: '#f5f5f5' } }}>
                <TableCell sx={{ textAlign: 'center' }}>
                  {renderTrophyIcon(index)}
                </TableCell>
                <TableCell>
                  <Typography variant="body1" color="textPrimary">{purchaser.name}</Typography>
                </TableCell>
                <TableCell sx={{ textAlign: 'center' }}>
                  <Typography variant="h5" fontWeight="bold">{purchaser.ticketCount}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default TopPurchasers;
