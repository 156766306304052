import * as React from 'react';
import { Grid } from '@mui/material';
import { Award } from '../../store/interfaces/IRaffle';
import { AwardDetail } from './awardDetail';


interface AwardInformationProps {
  award: Award
}

export function AwardInformation({ award }: AwardInformationProps) {

  return (
      <Grid item xs={12} lg={12} sm={12} xl={12} md={12} paddingX={2}>
        <AwardDetail award={award} />
      </Grid>
  );
}
