import {
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
} from '@mui/material';
import { BANK_ACCOUNTS, BankAccountItem } from '../../constants/bankAccounts';
import BinanceLogo from "../../assets/images/svg/binance.png";
import PaypalLogo from "../../assets/images/svg/paypal.png";
import { getPrimaryColorByAccountId, getThemeByAccountId } from '../../utils/styleConstants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/types/rootState';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { useGetActiveRaffleQuery } from '../../store/api';
import { useEffect, useState } from 'react';

interface BankAccountsProps {
    ticketSelectedCount: number; 
    accountId: string;
}

const BankAccounts = ({ticketSelectedCount, accountId}: BankAccountsProps) => {

    
    const { data: activeRaffleFounded, refetch } = useGetActiveRaffleQuery(accountId, {
    skip: accountId === "default" || accountId === "",
    });
    const [bankAccounts, setBankAccounts] = useState<BankAccountItem[]>([]);

    useEffect(() => {
        if (activeRaffleFounded && activeRaffleFounded.bankAccounts) {
            setBankAccounts(activeRaffleFounded.bankAccounts)
        } else {
            const config = BANK_ACCOUNTS.find(bankAccount => bankAccount.accountId === accountId);
            if (config != undefined) {
                setBankAccounts(config.bankAccounts)
            }
        }
    }, [activeRaffleFounded]);
    

    
    if (!bankAccounts) {
        return <></>
    }

    return (
        <>
            { bankAccounts && bankAccounts.map((item, index) => {
                return <BankAccount item={item} ticketSelectedCount={ticketSelectedCount} key={index} />;
            })}
            {/* <Carousel autoPlay={false}>
                {items.map((item, index) => {
                    return <BankAccount item={item} key={index} />;
                })}
            </Carousel> */}
            <br />
        </>
    );
}

interface BankAccountProps {
    item: BankAccountItem;
    ticketSelectedCount: number;
}


export function BankAccount({ item, ticketSelectedCount }: BankAccountProps) {
    const accountId = useSelector(({ account }: RootState) => account.id);

    const containerStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gap: '16px',
        padding: '20px',
        borderRadius: '15px',
        margin: 12,
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
        border: `2px solid ${getPrimaryColorByAccountId(accountId)}`,
        backgroundColor: getThemeByAccountId(accountId) === 'dark' ? '#2c2c2c' : '#ffffff',
    };

    const headerStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: '16px',
    };

    const logoStyle = {
        height: '24px',
        marginLeft: '8px',
        borderRadius: '4px',
    };

    const infoStyle = {
        display: 'grid',
        gap: '8px',
    };

    const conversionStyle = {
        backgroundColor: getPrimaryColorByAccountId(accountId),
        borderRadius: '8px',
        padding: '10px',
        color: getThemeByAccountId(accountId) === 'dark' ? '#ffffff' : '#000000',
        textAlign: 'center',
        marginTop: '16px',
    } as any;

    const totalStyle = {
        textAlign: 'center',
        padding: '10px',
        borderRadius: '8px',
        backgroundColor: getPrimaryColorByAccountId(accountId),
        color: '#ffffff',
    } as any;

    return (
        <div style={containerStyle}>
            <div style={headerStyle}>
                <Typography variant='h5' fontWeight="bold">
                    {item.bank}
                    {item.bank === 'Binance' && <img
                        style={logoStyle}
                        src={BinanceLogo}
                        alt="Binance Logo"
                    />}
                    {item.bank === 'Paypal' && <img
                        style={logoStyle}
                        src={PaypalLogo}
                        alt="Paypal Logo"
                    />}
                </Typography>
            </div>
            <div style={infoStyle}>
                {item.dni && (
                    <Typography variant='body1'>{`${item.dni}`}</Typography>
                )}
                {item.name && (
                    <Typography variant='body1'>{`Nombre: ${item.name}`}</Typography>
                )}
                {item.accountType && (
                    <Typography variant='body1'>{`Tipo de cuenta: ${item.accountType}`}</Typography>
                )}
                {item.accountNumber && (
                    <Typography variant='body1'>{`N° de cuenta: ${item.accountNumber}`}</Typography>
                )}
                {item.ticketPrice && (
                    <Typography variant='body1'>{`Precio del ticket: ${item.ticketPrice}`}</Typography>
                )}
                {item.conversion && item.conversion.dolarPrice !== 0 && (
                    <div style={conversionStyle}>
                        <Typography fontWeight="bold">
                            Conversión: {`1$ = ${item.conversion.dolarPrice} ${item.conversion.dolarPriceCurrency}`}
                        </Typography>
                        {ticketSelectedCount > 0 &&
                            <Typography fontWeight="bold">
                                {`${ticketSelectedCount} x ${item.ticketPrice} = ${(item.ticketPriceAmount * ticketSelectedCount).toFixed(2)} ${item.ticketCurrency}`}
                            </Typography>
                        }
                    </div>
                )}
                {ticketSelectedCount > 0 && (
                    <div style={totalStyle}>
                        <Typography variant='h6' fontWeight="bold">
                            {`Total a transferir`}
                        </Typography>
                        <Typography variant='h6' fontWeight="bold">
                            {`${(item.ticketPriceAmount * ticketSelectedCount).toFixed(2)} ${item.ticketCurrency}`}
                        </Typography>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BankAccounts;
