import { Box, Grid, TextField, Typography, Button, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CreateStepperRaffleProps } from "../../../../domain/CreateStepperRaffleProps";

export const BankAccountSteps = ({ resource, setResource, setDisableNext }: CreateStepperRaffleProps) => {
  const [mainFeaturesResource, setRaffleMainFeaturesResource] = useState(resource);

  useEffect(() => {
    setResource(mainFeaturesResource);
  }, [mainFeaturesResource, setResource]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;

    if (name.startsWith("bankAccounts")) {
      const [_, fieldName, index] = name.split("_");
      const updatedBankAccounts = [...mainFeaturesResource.bankAccounts];
      const bankAccountIndex = Number(index);

      if (fieldName === "name") {
        updatedBankAccounts[bankAccountIndex].name = value;
      } else if (fieldName === "dni") {
        updatedBankAccounts[bankAccountIndex].dni = value;
      } else if (fieldName === "bank") {
        updatedBankAccounts[bankAccountIndex].bank = value;
      } else if (fieldName === "accountType") {
        updatedBankAccounts[bankAccountIndex].accountType = value;
      } else if (fieldName === "accountNumber") {
        updatedBankAccounts[bankAccountIndex].accountNumber = value;
      } else if (fieldName === "ticketPrice") {
        updatedBankAccounts[bankAccountIndex].ticketPrice = value;
      } else if (fieldName === "ticketPriceAmount") {
        updatedBankAccounts[bankAccountIndex].ticketPriceAmount = Number(value);
      } else if (fieldName === "ticketCurrency") {
        updatedBankAccounts[bankAccountIndex].ticketCurrency = value;
      }
      
      if (addConvertion) {
        if (fieldName === "conversionDolarPrice") {
          // @ts-ignore: Unreachable code error
          updatedBankAccounts[bankAccountIndex].conversion.dolarPrice = Number(value);
        } else if (fieldName === "conversionDolarPriceCurrency") {
          // @ts-ignore: Unreachable code error
          updatedBankAccounts[bankAccountIndex].conversion.dolarPriceCurrency = value;
        }
      }
      setRaffleMainFeaturesResource((prevState) => ({
        ...prevState,
        bankAccounts: updatedBankAccounts,
      }));
      return;
    }

    setRaffleMainFeaturesResource((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [addConvertion, setAddConvertion] = useState<boolean>(false);

  const addBankAccount = () => {
    setRaffleMainFeaturesResource((prevState) => ({
      ...prevState,
      bankAccounts: [
        ...prevState.bankAccounts,
        {
          ticketPriceAmount: 0,
          ticketCurrency: "CLP",
        },
      ],
    }));
  };

  const removeBankAccount = (indexToRemove: any) => {
    setRaffleMainFeaturesResource((prevState) => ({
      ...prevState,
      bankAccounts: prevState.bankAccounts.filter((_, index) => index !== indexToRemove),
    }));
  };

  useEffect(() => {
    if (setDisableNext !== undefined) {
      setResource(mainFeaturesResource);
      const isFormValid =
        mainFeaturesResource.name.trim() !== "" &&
        mainFeaturesResource.ticketPrice > 0 &&
        mainFeaturesResource.limit > 0 &&
        mainFeaturesResource.type !== "" &&
        mainFeaturesResource.availablePaymentMethods.length > 0 &&
        mainFeaturesResource.bankAccounts.length > 0 &&
        mainFeaturesResource.bankAccounts.every(
          (account) =>
            account.name &&
            account.dni &&
            account.bank &&
            account.accountType &&
            account.accountNumber &&
            account.ticketPriceAmount > 0 &&
            account.ticketCurrency
        );
      setDisableNext(!isFormValid);
    }
  }, [mainFeaturesResource, setResource, setDisableNext]);

  return (
    <Box p={2}>
      <Typography variant="h5" gutterBottom>
        Bank Accounts
      </Typography>
      {mainFeaturesResource.bankAccounts.map((bankAccount, index) => (
        <Box key={index} mb={3} border={1} p={2} borderRadius={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Typography variant="subtitle1">Cuenta bancaria {index + 1}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Nombre"
                variant="outlined"
                fullWidth
                name={`bankAccounts_name_${index}`}
                value={bankAccount.name || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="DNI"
                variant="outlined"
                fullWidth
                name={`bankAccounts_dni_${index}`}
                value={bankAccount.dni || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Banco"
                variant="outlined"
                fullWidth
                name={`bankAccounts_bank_${index}`}
                value={bankAccount.bank || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Tipo de cuenta"
                variant="outlined"
                fullWidth
                name={`bankAccounts_accountType_${index}`}
                value={bankAccount.accountType || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Número de cuenta"
                variant="outlined"
                fullWidth
                name={`bankAccounts_accountNumber_${index}`}
                value={bankAccount.accountNumber || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Precio del ticket descripción"
                variant="outlined"
                fullWidth
                name={`bankAccounts_ticketPrice_${index}`}
                value={bankAccount.ticketPrice || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Precio ticket"
                variant="outlined"
                fullWidth
                type="number"
                name={`bankAccounts_ticketPriceAmount_${index}`}
                value={bankAccount.ticketPriceAmount.toString()}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Moneda"
                variant="outlined"
                fullWidth
                name={`bankAccounts_ticketCurrency_${index}`}
                value={bankAccount.ticketCurrency || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>
            
            <Grid item xs={12} sm={12}>
              <Checkbox
                checked={addConvertion}
                onChange={() => {
                  if (!addConvertion) {
                    bankAccount.conversion = {
                      dolarPrice: 0,
                      dolarPriceCurrency: "BS",
                    }
                  } else {
                    bankAccount.conversion = undefined
                  }
                  setAddConvertion(!addConvertion)
                }}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography display={"inline"}>Agregar conversión</Typography>
              
            </Grid>

            {addConvertion && bankAccount.conversion &&  <Grid item xs={12} sm={6}>
              <TextField
                label="Precio del dolar"
                variant="outlined"
                fullWidth
                name={`bankAccounts_conversionDolarPrice_${index}`}
                value={bankAccount.conversion.dolarPrice || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>}
            {addConvertion && bankAccount.conversion &&  <Grid item xs={12} sm={6}>
            <TextField
                label="Moneda"
                variant="outlined"
                fullWidth
                name={`bankAccounts_conversionDolarPriceCurrency_${index}`}
                value={bankAccount.conversion.dolarPriceCurrency || ""}
                onChange={handleChange}
                margin="normal"
              />
            </Grid>}
          </Grid>
          <Button
            variant="outlined"
            color="error"
            onClick={() => removeBankAccount(index)}
            style={{ marginTop: 10 }}
          >
            Eliminar cuenta bancaria
          </Button>
        </Box>
      ))}
      <Button
        variant="outlined"
        onClick={addBankAccount}
        style={{ marginTop: 10 }}
      >
        Agregar cuenta bancaria
      </Button>
    </Box>
  );
};
