import { CSSProperties } from 'react';
import Carousel from 'react-material-ui-carousel'
import { useIsMobileView } from '../../utils/responsive';
import { Box } from '@mui/material';
import { getPrimaryColorByAccountId, styleConstants } from '../../utils/styleConstants';
import { useAppSelector } from '../../store/hooks/useAppSelector';
import { RootState } from '../../store/types/rootState';

interface RaffleImagesProps {
    images: string[]
}

export function RaffleImages({ images }: RaffleImagesProps)
{

    const accountId = useAppSelector(
        ({ account }: RootState) => account.id,
      );

    const isMobile = useIsMobileView();

    const sx: CSSProperties = {
        width: isMobile ? '100%': '100%',
        height: isMobile ? '566px': '100%',
        display: 'flex',
    }
    return (
        <Box>
        <Carousel indicators={false} height={isMobile ? '560px': '100vh'} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {
                images.map( (imageUrl, i) => 
                    <img key={imageUrl} alt={`${i}`} src={imageUrl} style={sx}></img> 
                )
            }
        </Carousel>
        </Box>
    )
}
