import { ThemeProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import store from "./store";
import { createAppTheme } from "./utils/AppTheme";
import { Home } from "./components/pages/Home";
import Login from "./components/pages/Login";
import { CreatePage } from "./components/pages/Create";
import PrivateRoute from "./components/utils/PrivateRoute";
import { DashboardPage } from "./components/pages/Dashboard";
import Header from "./components/layout/header";
import Payment from "./components/pages/Payment";
import NotFoundPage from "./components/layout/404";
import { Box, CssBaseline } from "@mui/material";
import { PurchaseOrderPage } from "./components/pages/PurchaseOrder";
import MobileBottomNavigation from "./components/layout/mobileBottomNavigation";
import { PurchasersPage } from "./components/pages/Purchasers";
import { WinnersPage } from "./components/pages/Winners";
import { SuccessPurchasePage } from "./components/pages/SuccessPurchase";
import { SoldTicketsPage } from "./components/pages/SoldTickets";
function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={createAppTheme(store.getState())}>
        <CssBaseline />
        <SnackbarProvider>
            <BrowserRouter>
            <Header />
             <Box>
              <Routes>
                  <Route path={"/"} element={<Home />} />
                  <Route path={"/successPurchase"} element={<SuccessPurchasePage />} />
                  <Route path={"/purchaseOrders"} element={
                    <PrivateRoute>
                      <PurchaseOrderPage />
                    </PrivateRoute>
                  } />
                  <Route path={"/create"} element={
                    <PrivateRoute>
                      <CreatePage />
                    </PrivateRoute>
                  } />
                  <Route path={"/purchasers"} element={
                    <PrivateRoute>
                      <PurchasersPage />
                    </PrivateRoute>
                  } />
                  <Route
                      path={"/dashboard"}
                      element={
                        <PrivateRoute>
                          <DashboardPage />
                        </PrivateRoute>
                      }
                    />
                  <Route path={"/winners"} element={
                    <PrivateRoute>
                      <WinnersPage />
                    </PrivateRoute>
                  } />
                  <Route path={"/soldTickets"} element={
                    <PrivateRoute>
                      <SoldTicketsPage />
                    </PrivateRoute>
                  } />
                  <Route path={"/login"} element={<Login/>} />
                  <Route path="*" element={<NotFoundPage />} />
                </Routes>
             </Box>
             <MobileBottomNavigation />
            </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
