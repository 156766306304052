import { AwardsStep } from "../components/raffle/create/steps/awardsStep";
import { MainFeaturesStep } from "../components/raffle/create/steps/mainFeaturesStep";
import { SummaryRaffleStep } from "../components/raffle/create/steps/summaryRaffleStep";

export const STEPS = [
    {
      label: 'Caracteristicas principales',
      component: MainFeaturesStep
    },
    {
      label: 'Premios',
      component: AwardsStep
    },
    {
      label: 'Cuentas bancarías',
      component: AwardsStep
    },
    {
      label: 'Resumen de la rifa',
      component: SummaryRaffleStep
    },
  ];
