import { Box, Chip, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import { useIsMobileView } from '../../utils/responsive';
import { Award } from '../../store/interfaces/IRaffle';
import { AWARD_TYPES_ICONS } from '../../constants/icons';

// Styled component for ListItem to enhance appearance
const StyledListItem = styled(ListItem)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

interface AwardDetailProps {
  award: Award;
}

export const AwardDetail = ({ award }: AwardDetailProps) => {
  const isMobile = useIsMobileView();

  return (
    <Grid container spacing={2}>
      {/* Award Chip Section */}
      <Grid item xs={12} lg={12} sm={12} xl={12} md={12} sx={{ zIndex: 2}}>
        <Chip
          sx={{
            display: 'flex',
            alignItems: 'center',
            fontSize: isMobile ? '1.25rem' : '1.875rem', // 20px for mobile and 30px for larger screens
            height: 'auto',
            '& .MuiChip-label': {
              display: 'flex',
              alignItems: 'center',
            },
          }}
          label={
            <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center' }}>
              <EmojiEventsIcon sx={{ color: award.order === 0 ? 'gold' : 'silver', mr: 1 }} />
              {award.name}
            </Typography>
          }
          variant="outlined"
        />
      </Grid>

      {/* Award Features List Section */}
      <Grid item xs={12} lg={12} sm={12} xl={12} md={12}>
        <List sx={{ zIndex: 2}}>
          {award.features.map((feature, index) => (
            <StyledListItem key={index}>
              <ListItemIcon>
                {AWARD_TYPES_ICONS.find((icon) => icon.id === award.type)?.icon}
              </ListItemIcon>
              <ListItemText primary={feature} />
            </StyledListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};
